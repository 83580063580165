<template>
  <v-main>
    <v-container class="mt-md-4">
      <v-card>
        <v-card-title>
          <div class="text-h4">Wählhilfe</div>
        </v-card-title>
        <v-card-text>
          <div v-if="!contactData">Kein Anruf gestartet</div>
          <div v-else>
            <table>
              <tr>
                <td>Vorname:</td>
                <td class="black--text text-h6" style="line-height: inherit">{{ contactData["firstName"] }}</td>
              </tr>
              <tr>
                <td>Nachname:</td>
                <td class="black--text text-h6" style="line-height: inherit">{{ contactData["lastName"] }}</td>
              </tr>
              <tr>
                <td class="pr-2">Telefonnummer:</td>
                <td class="black--text text-body-1">{{ contactData["phoneNumber"] }}</td>
              </tr>
              <tr>
                <td>E-Mail Adresse:</td>
                <td class="black--text text-body-1" style="word-wrap: anywhere; word-break: break-all;">
                  {{ contactData["emailAddress"] }}
                </td>
              </tr>
            </table>
          </div>
        </v-card-text>
        <v-card-actions @click="buttonClicked">
          <v-btn block outlined color="primary" large :disabled="!contactData" :href="'tel:' + phoneNumber"
                 >
            Nummer öffnen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: "DialAssistant",
  data: () => ({
    emailsShown: false,
    polling: null
  }),
  computed: {
    contactData() {
      let data = this.$store.state.applicationData["synchronization/selected_contact"];
      if (data && data.hasOwnProperty("phoneNumber")) {
        return data;
      } else {
        return null;
      }
    },
    phoneNumber() {
      if (this.contactData) {
        return this.contactData["phoneNumber"];
      } else {
        return "";
      }
    }
  },
  mounted() {
    this.fetchSelectedContact();
    this.startDataPolling();
  },
  methods: {
    fetchSelectedContact() {
      this.$store.dispatch("getApplicationDataSingleEntry", "synchronization/selected_contact");
    },
    startDataPolling() {
      this.polling = setInterval(() => {
        this.fetchSelectedContact();
      }, 800);
    },
    buttonClicked() {
      console.log("clicked");
    }
  },
  beforeDestroy() {
    clearInterval(this.polling);
  }
};
</script>

<style scoped>
@media (min-width: 1264px) {
  .container {
    max-width: 1185px;
  }
}

tr {
  vertical-align: baseline;
}
</style>